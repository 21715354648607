import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts'
import { Container } from '../components'
import styled from '@emotion/styled'
import { theme } from '../assets/theme'
import GatsbyLink from 'gatsby-link'

const Poetries = ({ data, pageContext: context, location }) => {
  const getLink = word => {
    const currentPath = location?.pathname
    console.log(currentPath)
    const splitPath = currentPath?.split('/')
    console.log(splitPath.length)
    if (splitPath.length === 4) {
      splitPath[splitPath.length - 1] = word
      return splitPath.join('/')
    }
    return `${currentPath}/${word}`
  }

  return (
    <Layout
      data={data?.strapiPoetry}
      meta={context?.meta}
      menu={context?.menu}
      locale={context?.locale}
      location={location}>
      <SContainer>
      {context.currentWord && (
          <GatsbyLink
            style={{
              textDecoration: 'none',
            }}
            to={`/${context.locale}/poetries`}>
            <Button>Сбросить фильтр</Button>
          </GatsbyLink>
        )}
        <Body>
        <Filter>
          {context?.filterWords?.map(w => (
            <GatsbyLink
              style={{
                textDecoration: context.currentWord === w ? 'underline' : '',
              }}
              to={getLink(w)}>
              {w}
            </GatsbyLink>
          ))}
        </Filter>
        <Content>
          {data?.allStrapiPoetryList?.edges.map(({ node }) => (
            <GatsbyLink
              key={node.id}
              to={`/${context.locale}/poetries/${node.id}?word=${context.currentWord}`}>
              {node.title}
            </GatsbyLink>
          ))}
        </Content>
        </Body>
      </SContainer>
      <Container>
        <PoetriesBlock></PoetriesBlock>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query AllPoetries($locale: String!, $regex: String!) {
    strapiPoetry(locale: { eq: $locale }) {
      title
      background {
        url
      }
    }
    allStrapiPoetryList(
      filter: { locale: { eq: $locale }, title: { regex: $regex } }
    ) {
      edges {
        node {
          id
          title
          body
        }
      }
    }
  }
`

const PoetriesBlock = styled.div``

const Body = styled.div`
  display: flex;
`

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.5rem;
  text-transform: uppercase;

  a {
    color: ${theme.colors.black};
    text-decoration: none;
  }
`

const Content = styled.div`
  margin-left: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  a {
    color: ${theme.colors.black};
  }
`

const SContainer = styled(Container)`
  padding: 4rem 0 !important;
  flex-direction: column;

  ${() => theme.mqMax('lg')} {
    padding: 2rem 10px !important;
  }
`

const Button = styled.span`
  position: relative;
  display: block;
  color: ${theme.colors.black};
  background: none;
  border: none;
  cursor: pointer;
  order: 1;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  margin-bottom: 2rem;
  width: max-content;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    left: -3px;
    bottom: -3px;
    height: 2px;
    background: ${theme.colors.black};
  }

  &:hover {
    transform: translateX(3px);

    & + svg {
      transform: translateX(-3px);
    }
  }
`

export default Poetries
